import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import Terms from 'src/components/Legal/Terms'
import SEO from 'src/components/shared/SEO'

const TermsPage: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Terms & Conditions" location={location} />

    <Layout location={location}>
      <Terms />
    </Layout>
  </>
)

export default TermsPage
