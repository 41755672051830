import React from 'react'
import styled from 'styled-components'

import { P, SmallP } from '../shared/text'
import { fonts } from '../../styles/variables'
import LegalView from './LegalView'

const SectionHeader = styled(P)`
  font-family: ${fonts.larsseit.medium};
  margin: 30px 0 10px 0;
`

const StyledP = styled(SmallP)`
  margin-bottom: 16px;
`

const Lead = styled.span`
  display: inline-block;
  font-family: ${fonts.larsseit.medium};
  margin: 0 6px 0 0;
`

const Email = () => <a href="mailto:hello@petfinn.com">hello@petfinn.com</a>

const Terms = () => {
  return (
    <LegalView title="Terms & Conditions">
      <SectionHeader>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY:</SectionHeader>
      <StyledP>
        Welcome to www.petfinn.com. These Terms of Use describe the terms and conditions applicable
        to your access and use of the website at www.petfinn.com a “Site”. This document is a
        legally binding agreement between you as the user(s) of the Site (referred to as “you”,
        “your”, "Customer" or “User” hereinafter) and the Finn Wellness, LLC. entity (referred to as
        “we”, “our”, “Finn” or “petfinn.com” hereinafter).
      </StyledP>

      <SectionHeader>1. Application and Acceptance of the Terms</SectionHeader>
      <StyledP>
        <Lead>1.1</Lead> Your use of the Site and petfinn.com’s services and products (collectively
        as the “Services” hereinafter) is subject to the terms and conditions contained in this
        document as well as the Privacy Policy and any other rules and policies of the Site that
        petfinn.com may publish from time to time. This document and such other rules and policies
        of the Site are collectively referred to below as the “Terms”. By accessing the Site or
        using the Services, you agree to accept and be bound by the Terms. Please do not use the
        Services or the Site if you do not accept all of the Terms.
      </StyledP>
      <StyledP>
        <Lead>1.2</Lead> You may not use the Services and may not accept the Terms if (a) you are
        not of legal age (at least 16 years old) to form a binding contract with petfinn.com, or (b)
        you are not permitted to receive any Services under the laws of local countries / regions
        including the country / region in which you are resident or from which you use the Services.
      </StyledP>
      <StyledP>
        <Lead>1.3</Lead> You acknowledge and agree that petfinn.com may amend any Terms at any time
        by posting the relevant amended and restated Terms on the Site. By continuing to use the
        Services or the Site, you agree that the amended Terms will apply to you.
      </StyledP>
      <StyledP>
        <Lead>1.4</Lead> If petfinn.com has posted or provided a translation of the English language
        version of the Terms, you agree that the translation is provided for convenience only and
        that the English language version will govern your uses of the Services or the Site.
      </StyledP>
      <StyledP>
        <Lead>1.5</Lead> You may be required to enter into a separate agreement, whether online or
        offline, with petfinn.com or our affiliate for any Service (“Additional Agreements”). If
        there is any conflict or inconsistency between the Terms and an Additional Agreement, the
        Additional Agreement shall take precedence over the Terms only in relation to that Service
        concerned.
      </StyledP>
      <StyledP>
        <Lead>1.6</Lead> The Terms may not otherwise be modified except in writing by an authorized
        officer of petfinn.com.
      </StyledP>

      <SectionHeader>2. Provision of Services</SectionHeader>
      <StyledP>
        <Lead>2.1</Lead> You must register on the Site in order to access and use some Services.
        Further, petfinn.com reserves the right, without prior notice, to restrict access to or use
        of certain Services (or any features within the Services) to users (“Users”) or subject to
        other conditions that petfinn.com may impose at our discretion.
      </StyledP>
      <StyledP>
        <Lead>2.2</Lead> Some Services may be provided by petfinn.com’s affiliates on behalf of
        petfinn.com.
      </StyledP>
      <StyledP>
        <Lead>2.3</Lead> Services (or any features within the Services) may vary for different
        regions and countries. No warranty or representation is given that a particular Service or
        feature or function thereof or the same type and extent of the Service or features and
        functions thereof will be available for Users. petfinn.com may in our sole discretion limit,
        deny or create different levels of access to and use of any Services (or any features within
        the Services) with respect to different Users.
      </StyledP>
      <StyledP>
        <Lead>2.4</Lead>petfinn.com may launch, change, upgrade, impose conditions to, suspend, or
        stop any Services (or any features within the Services) without prior notice except that in
        case of a fee-based Service, such changes will not substantially adversely affect the paying
        Users in enjoying that Service.
      </StyledP>

      <SectionHeader>3. Users Generally</SectionHeader>
      <StyledP>
        <Lead>3.1</Lead>As a condition of your access to and use of the Site or Services, you agree
        that you will comply with all applicable laws and regulations when using the Site or
        Services.
      </StyledP>
      <StyledP>
        <Lead>3.2</Lead>You agree to use the Site or Services solely for your own private and
        internal purposes. You agree that (a) you will not copy, reproduce, download, re-publish,
        sell, distribute or resell any Services or any information, text, images, graphics, video
        clips, sound, directories, files, databases or listings, etc available on or through the
        Site (the “Site Content”), and (b) you will not copy, reproduce, download, compile or
        otherwise use any Site Content for the purposes of operating a business that competes with
        petfinn.com, or otherwise commercially exploiting the Site Content. Systematic retrieval of
        Site Content from the Site to create or compile, directly or indirectly, a collection,
        compilation, database or directory (whether through robots, spiders, automatic devices or
        manual processes) without written permission from petfinn.com is prohibited. Use of any
        content or materials on the Site for any purpose not expressly permitted in the Terms is
        prohibited.
      </StyledP>
      <StyledP>
        <Lead>3.3</Lead>You must read petfinn.com’s Privacy Policy which governs the protection and
        use of personal information about Users in the possession of petfinn.com and our affiliates.
        You accept the terms of the Privacy Policy and agree to the use of the personal information
        about you in accordance with the Privacy Policy.
      </StyledP>
      <StyledP>
        <Lead>3.4</Lead>petfinn.com may allow Users access to content, products or services offered
        by third parties through hyperlinks (in the form of word link, banners, channels or
        otherwise), API or otherwise to such third parties' websites. You are cautioned to read such
        websites' terms and conditions and/or privacy policies before using the Site. You
        acknowledge that petfinn.com has no control over such third parties' website, does not
        monitor such website, and shall not be responsible or liable to anyone for such web site, or
        any content, products or services made available on such website.
      </StyledP>
      <StyledP>
        <Lead>3.5</Lead>You agree not to undertake any action to undermine the integrity of the
        computer systems or networks of petfinn.com and/or any other User nor to gain unauthorized
        access to such computer systems or networks.
      </StyledP>
      <StyledP>
        <Lead>3.6</Lead>You agree not to undertake any action which may undermine the integrity of
        petfinn.com’s feedback system, such as leaving positive feedback for yourself using
        secondary email accounts or through third parties or by leaving unsubstantiated negative
        feedback for another User.
      </StyledP>
      <StyledP>
        <Lead>3.7</Lead>By posting or displaying any information, content or material (“User
        Content”) on the Site or providing any User Content to petfinn.com or our representative(s),
        you grant an irrevocable, perpetual, worldwide, royalty-free, and sub-licensable (through
        multiple tiers) license to petfinn.com to display, transmit, distribute, reproduce, publish,
        duplicate, adapt, modify, translate, create derivative works, such as using as product
        description on petfinn.com, advertising outside petfinn.com and otherwise use any or all of
        the User Content in any form, media, or technology now known or not currently known in any
        manner and for any purpose which may be beneficial to the operation of the Site, the
        provision of any Services and/or the business of the User. You confirm and warrant to
        petfinn.com that you have all the rights, power and authority necessary to grant the above
        license.
      </StyledP>

      <SectionHeader>4. Transactions</SectionHeader>
      <StyledP>
        <Lead>4.1</Lead>Customers agree to provide all information and materials as may be
        reasonably required by petfinn.com in connection with orders placed at petfinn.com.
        petfinn.com has the right to suspend or terminate any Customers account if the Customer
        fails to provide the required information and materials or provides inaccurate or fraudulent
        information.
      </StyledP>
      <StyledP>
        <Lead>4.2</Lead> Each User agrees that petfinn.com shall not be liable or responsible for
        any damages, claims, liabilities, costs, harms, inconveniences, business disruptions or
        expenditures of any kind that may arise as a result of or in connection with any use or
        storage of any products purchased from petfinn.com.
      </StyledP>
      <StyledP>
        <Lead>4.3</Lead>Finn will maintain all applicable PCI DSS standards and protect all data
        provided by the cardholder.
      </StyledP>
      <StyledP>
        <Lead>4.1</Lead>When you make a purchase, you agree that the risk of loss transfers from
        Finn to you from the moment our shipping carrier delivers your order to the destination
        provided in your purchase.
      </StyledP>

      <SectionHeader>5. Limitation of Liability</SectionHeader>
      <StyledP>
        <Lead>5.1</Lead>TO THE MAXIMUM EXTENT PERMITTED BY LAW AND EXCEPT AS SET FORTH HEREIN OR IN
        WARRANTIES ISSUED BY PETFINN.COM, THE PRODUCTS AND SERVICES PROVIDED BY FINN ON OR THROUGH
        THE SITES ARE PROVIDED "AS IS", "AS AVAILABLE" AND “WITH ALL FAULTS”, AND FINN HEREBY
        EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
        TO, ANY WARRANTIES OF CONDITION, QUALITY, DURABILITY, PERFORMANCE, ACCURACY, RELIABILITY,
        MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. ALL SUCH WARRANTIES, REPRESENTATIONS,
        CONDITIONS, AND UNDERTAKINGS ARE HEREBY EXCLUDED.
      </StyledP>
      <StyledP>
        <Lead>5.2</Lead>TO THE MAXIMUM EXTENT PERMITTED BY LAW, FINN MAKES NO REPRESENTATIONS OR
        WARRANTIES ABOUT THE VALIDITY, ACCURACY, CORRECTNESS, RELIABILITY, QUALITY, STABILITY,
        COMPLETENESS OR CURRENTNESS OF ANY INFORMATION PROVIDED ON OR THROUGH THE SITES; PETFINN.COM
        DOES NOT REPRESENT OR WARRANT THAT THE MANUFACTURE, IMPORTATION, EXPORT, DISTRIBUTION,
        OFFER, DISPLAY, PURCHASE, SALE AND/OR USE OF PRODUCTS OR SERVICES OFFERED OR DISPLAYED ON
        THE SITES DOES NOT VIOLATE ANY THIRD PARTY RIGHTS; AND PETFINN.COM MAKES NO REPRESENTATIONS
        OR WARRANTIES OF ANY KIND CONCERNING ANY PRODUCT OR SERVICE OFFERED OR DISPLAYED ON THE
        SITES.
      </StyledP>
      <StyledP>
        <Lead>5.3</Lead>Any material downloaded or otherwise obtained through the Site is done at
        each User's sole discretion and risk and each User is solely responsible for any damage to
        petfinn.com’s computer system or loss of data that may result from the download of any such
        material. No advice or information, whether oral or written, obtained by any User from
        petfinn.com or through or from the Site shall create any warranty not expressly stated
        herein.
      </StyledP>
      <StyledP>
        <Lead>5.4</Lead>Each User hereby further agrees to indemnify and save petfinn.com, our
        affiliates, directors, officers and employees harmless, from any and all losses, damages,
        claims, liabilities (including legal costs on a full indemnity basis) which may arise,
        directly or indirectly, as a result of any claims asserted by Third Party Rights claimants
        or other third parties relating to products offered or displayed on the Site. Each User
        hereby further agrees that petfinn.com is not responsible and shall have no liability to
        you, for any material posted by others; including defamatory, offensive or illicit material
        and that the risk of damages from such material rests entirely with each User. petfinn.com
        reserves the right, at our own expense, to assume the exclusive defense and control of any
        matter otherwise subject to indemnification by you, in which event you shall cooperate with
        petfinn.com in asserting any available defenses.
      </StyledP>
      <StyledP>
        <Lead>5.5</Lead>petfinn.com shall not be liable for any special, direct, indirect, punitive,
        incidental or consequential damages or any damages whatsoever (including but not limited to
        damages for loss of profits or savings, business interruption, loss of information), whether
        in contract, negligence, tort, equity or otherwise or any other damages resulting from any
        of the following.
      </StyledP>
      <StyledP as="ul">
        <li>the use or the inability to use the Site or Services;</li>
        <li>
          any defect in goods, samples, data, information or services purchased or obtained from a
          User or any other third party through the Site;
        </li>
        <li>unauthorized access by third parties to data or private information of any User;</li>
        <li>statements or conduct of any User of the Site; or</li>
        <li>any matters relating to Services however arising, including negligence.</li>
      </StyledP>
      <StyledP>
        <Lead>5.6</Lead>Notwithstanding any of the foregoing provisions, the aggregate liability of
        petfinn.com, our employees, agents, affiliates, representatives or anyone acting on our
        behalf with respect to each User for all claims arising from the use of the Site or Services
        during any calendar year shall be limited to the greater of (a) the amount of purchase price
        the User has paid to petfinn.com or our affiliates during the calendar year and (b) $1,000.
        The preceding sentence shall not preclude the requirement by the User to prove actual
        damages. All claims arising from the use of the Site or Services must be filed within one
        (1) year from the date the cause of action arose.
      </StyledP>
      <StyledP>
        <Lead>5.7</Lead>The limitations and exclusions of liability to you under the Terms shall
        apply to the maximum extent permitted by law and shall apply whether or not petfinn.com has
        been advised of or should have been aware of the possibility of any such losses arising.
      </StyledP>

      <SectionHeader>6. Force Majeure</SectionHeader>
      <StyledP>
        <Lead>6.1</Lead>Under no circumstances shall petfinn.com be held liable for any delay or
        failure or disruption of the content or services delivered through the Site resulting
        directly or indirectly from acts of nature, forces or causes beyond our reasonable control,
        including without limitation, Internet failures, computer, telecommunications or any other
        equipment failures, electrical power failures, strikes, labor disputes, riots,
        insurrections, civil disturbances, shortages of labor or materials, fires, flood, storms,
        explosions, acts of God, war, governmental actions, orders of domestic or foreign courts or
        tribunals or non-performance of third parties.
      </StyledP>

      <SectionHeader>7. Intellectual Property Rights</SectionHeader>
      <StyledP>
        <Lead>7.1</Lead>petfinn.com is the sole owner or lawful licensee of all the rights and
        interests in the Services, Site and the Site Content. The Site and Site Content embody trade
        secrets and other intellectual property rights protected under worldwide copyright and other
        laws. All title, ownership and intellectual property rights in the Site and Site Content
        shall remain with petfinn.com, our affiliates or licensors of the Site Content, as the case
        may be. All rights not otherwise claimed under the Terms or by petfinn.com are hereby
        reserved.
      </StyledP>
      <StyledP>
        <Lead>7.2</Lead>"petfinn.com", “FINN” and related icons and logos are registered trademarks
        or trademarks or service marks of petfinn.com,
      </StyledP>
      <StyledP>
        <Lead>7.3</Lead> petfinn.com may have independent third parties involved in the provision of
        the Services (e.g., the authentication and verification service providers). You may not use
        any trademark, service mark or logo of such independent third parties without prior written
        approval from such parties
      </StyledP>

      <SectionHeader>8. Notices</SectionHeader>
      <StyledP>
        <Lead>8.1</Lead>All legal notices or demands to or upon petfinn.com shall be made in writing
        and sent to petfinn.com personally, by courier, certified mail, or facsimile to the
        following address:100 Crosby St Suite 506, New York, NY 10012. The notices shall be
        effective when they are received by petfinn.com in any of the above-mentioned manners.
      </StyledP>
      <StyledP>
        <Lead>8.2</Lead>All legal notices or demands to or upon a User shall be effective if either
        delivered personally, sent by courier, certified mail, by facsimile or email to the
        last-known correspondence, fax or email address provided by the User to petfinn.com, or by
        posting such notice or demand on an area of the Site that is publicly accessible without a
        charge. Notice to a User shall be deemed to be received by such User if and when.
      </StyledP>
      <StyledP as="ul">
        <li>
          petfinn.com is able to demonstrate that communication, whether in physical or electronic
          form, has been sent to such User, or
        </li>
        <li>
          Immediately upon petfinn.com posting such notice on an area of the Site that is publicly
          accessible without charger
        </li>
      </StyledP>
      <StyledP>
        <Lead>8.3</Lead>You agree that all agreements, notices, demands, disclosures and other
        communications that petfinn.com sends to you electronically satisfy the legal requirement
        that such communication should be in writing
      </StyledP>

      <SectionHeader>9. General Provisions</SectionHeader>
      <StyledP>
        <Lead>9.1</Lead>Subject to any additional agreements, the Terms constitute the entire
        agreement between you and petfinn.com with respect to and govern your use of the Site and
        Services, superseding any prior written or oral agreements in relation to the same subject
        matter herein.
      </StyledP>
      <StyledP>
        <Lead>9.2</Lead>petfinn.com and you are independent contractors, and no agency, partnership,
        joint venture, employee-employer or franchiser-franchisee relationship is intended or
        created by the Terms.
      </StyledP>
      <StyledP>
        <Lead>9.3</Lead>If any provision of the Terms is held to be invalid or unenforceable, such
        provision shall be deleted and the remaining provisions shall remain valid and be enforced.
      </StyledP>
      <StyledP>
        <Lead>9.4</Lead>Headings are for reference purposes only and in no way define, limit,
        construe or describe the scope or extent of such section.
      </StyledP>
      <StyledP>
        <Lead>9.5</Lead>petfinn.com’s failure to enforce any right or failure to act with respect to
        any breach by you under the Terms will not constitute a waiver of that right nor a waiver of
        petfinn.com’s right to act with respect to subsequent or similar breaches.
      </StyledP>
      <StyledP>
        <Lead>9.6</Lead>petfinn.com shall have the right to assign the Terms (including all of our
        rights, titles, benefits, interests, and obligations and duties in the Terms to any person
        or entity (including any affiliates of petfinn.com). You may not assign, in whole or part,
        the Terms to any person or entity.
      </StyledP>
      <StyledP>
        <Lead>9.7</Lead>The Terms and Conditions shall be governed by the laws of the State of New
        York without regard to its conflict of law provisions.
      </StyledP>

      <SectionHeader>10. Returns, Refunds, and Cancellations</SectionHeader>
      <StyledP>
        <Lead>10.1</Lead>
        <Lead>Cancellations Policy.</Lead> You may cancel your subscription at anytime by visiting
        your User Account page and following the instructions located therein, or by contacting us
        at hello@petfinn.com. Changes to, or cancellations of, your subscription, may also be
        subject to additional timing and other restrictions set forth by Finn Wellness. If you
        cancel the Subscription Service, your subscription will terminate immediately except as to
        ANY ORDER(S) IDENTIFIED AS “PROCESSING” OR “SHIPPED” ON YOUR USER ACCOUNT PAGE which CANNOT
        BE CANCELED. YOU WILL BE RESPONSIBLE FOR ALL CHARGES (INCLUDING ANY APPLICABLE TAXES AND
        OTHER CHARGES) INCURRED WITH RESPECT TO ANY ORDER IDENTIFIED AS "PROCESSING" OR "SHIPPED"
        PRIOR TO THE CANCELLATION OF YOUR SUBSCRIPTION. If you cancel the Subscription Service,
        there is no guarantee that your User Content will be saved.
      </StyledP>
      <StyledP>
        <Lead>10.2</Lead>
        <Lead>Return Policy.</Lead> We have a 60-day return policy, which means you have 60 days
        after receiving your item to request a return. If you are dissatisfied with your product for
        any reason, please contact <Email /> so we can evaluate the issue and make it right.
        <br />
        <br />
        To be eligible for a return, you must provide your order number and reason for return.
        You'll also need the receipt or proof of purchase.
      </StyledP>
      <StyledP>
        <Lead>10.3</Lead>
        <Lead>Refunds and Credits.</Lead> If you are dissatisfied with your product and/or something
        is missing from your package, please contact us at hello@petfinn.com and we may, in our sole
        discretion, issue a credit and/or refund. Unless otherwise provided by law or by a
        particular offer from us, all purchases are final and non-refundable.
      </StyledP>
    </LegalView>
  )
}

export default Terms
